import request from '../utils/request';

export const fetchData = (query) => {

    var that = this

    var URL = 'http://resetface.visitors.hlsywy.com/class' + query.url + '?Token=' + window.sessionStorage.getItem('Token')
    // request({
    //     url: 'http://resetface.visitors.hlsywy.com//class/get_loginInfo.cls.php?Token=' + window.sessionStorage.getItem('Token'),
    //     method: query.method || 'post',
    //     dataType: 'json',
    //     data: query.data,
    // })
    //     .then((res) => {
    //         if (res.code == -1) {
    //             window.location.href = "http://resetface.visitors.hlsywy.com//#/login";
    //         }
    //     }).catch((response) => {
    //         alert("网络错误");
    //         window.location.href = "http://resetface.visitors.hlsywy.com//#/login";
    //     });

    return request({
        url: URL,
        method: query.method || 'post',
        dataType: 'json',
        data: query.data,
    })

};
